@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: "Outfit", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.shadow {
  box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);
}