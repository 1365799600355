.title1 {
    font-weight: 700;
    font-size: 80px;
    text-shadow: 2px 2px 2px #f8f6f6;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: #0f00000a;
    color: #1313ffd5;
}

.title2 {
    font-weight: 500;
    font-size: 50px;
    text-shadow: 2px 2px 2px #f8f6f6;
    text-align: center;
    position: relative;
    top: 45%;
    transform: translateY(-50%);
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: #0f00000a;
    color: #1313ffd5;
}

@media screen and (max-width: 768px) {
    .title1 {
        font-size: 60px;
        margin-bottom: 50px;
    }
}