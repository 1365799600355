.companyNameAndLogo {
  display: flex;
  align-items: center;
}

.navbar {
  background-color: rgb(241, 241, 241);
}

.nav-link {
  font-size: 19px !important;
}

.dropdown:hover>.dropdown-menu,
.dropend:hover>.dropdown-menu {
  display: block;
  margin-top: 0.125em;
  margin-left: 0.125em;
}

@media screen and (min-width: 769px) {
  .dropend:hover>.dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
  }

  .dropend .dropdown-toggle {
    margin-left: 0.5em;
  }
}

.navbarItems {
  display: flex;
  justify-content: end;
}

@media screen and (max-width: 1080px) {
  .navbarItems {
    display: block;
    margin-left: 15px;
  }

  .companyName {
    font-size: 22px !important;
    margin-left: 5px;
  }
}