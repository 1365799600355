.homeAboutUsBackgroundImage {
    position: relative;
    height: 30vh;
    /* Adjust the height as needed */
    background-image: url('/public/Assets/HomeAboutUsBackgroundImage.jpg');
    /* Replace with your image URL */
    background-size: cover;
    background-attachment: fixed;
    padding-top: 50px;
    padding-bottom: 350px;
   
}

.icon{
    border-radius: 50%;
    padding: 15px;
    background-color: red;
}
.homeAboutUsCount{
    font-size: 48px;
    font-weight: 700;
    color: #fff;
}
.homeAboutUs{
    color: #fff;
}