.contactUsBannerContainer {
    background: linear-gradient(rgba(110, 110, 110, 0.7), rgba(0, 0, 0, 0.5)), url('../../Assets/contactBg.jpg');
    background-attachment: fixed;
    background-size: cover;
    height: 70vh;
}

.contactCard {
    margin-top: 50px;
    border: 2px solid rgba(131, 131, 131, 0.1);
    box-sizing: border-box;
    height: 320px;
    box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);
}

.contactCardTitle {
    font-family: 'Oswald', sans-serif;
    margin-top: 20px;
    font-size: 35px;
}

.contactCardDesc {
    font-family: 'Signika Negative', sans-serif;
    margin-top: 20px;
    font-size: 18px;
    padding: 0px 20px 0px 20px;
}